<template>
  <BBadge>CoBrand</BBadge>
</template>

<script>
import { BBadge } from 'bootstrap-vue';
export default {
  components: {
    BBadge,
  },
};
</script>

<style></style>
